<template>
<div>
  <div class="dongtai-item">
    <div class="dt-avatar">
      <UserAvatar :fullName="getOwnerName()" :name="getOwnerName().slice(0,3)" width='60px' height='60px' :userId="getOwnerId()" />
    </div>

    <v-container class="dt-content pa-0 px-2">
      <v-row class="pa-0 ma-0 py-1">
        <div class="text-subtitle-1 font-weight-bold" style="cursor:pointer;">{{getOwnerName()}}</div>
        <div class="pl-1">{{objectLabel[dObject.mode]}} </div>
      </v-row>

      <v-container v-if="this.dObject.mode=='intercom'" class="pa-0 ">
        <v-row class="pa-0 ma-0 pa-0 " justify="space-between" align="start">
          {{ this.dObject.obj.ic_description  }}
        </v-row>
        <v-row class="pa-0 ma-0 pa-0 " justify="space-between" align="start">
          <span style=" color: #ccc;">
            {{ this.dObject.obj.created_at | chinaFormatDate }}
          </span>
        </v-row>
      </v-container>

      <v-container v-if="this.dObject.mode=='share'" class="pa-0 ">     
        <v-row class="pa-0 ma-0 pa-0 " justify="space-between" align="start">
          {{ this.dObject?.obj?.ic_description  }}
        </v-row>
        <v-row class="pa-0 ma-0 pa-0 " justify="space-between" align="start">
          <span style=" color: #ccc;">
            {{ this.dObject.obj.created_at | chinaFormatDate }}
          </span>
        </v-row>
      </v-container>

      <v-container v-if="this.dObject.mode=='task'">
        <v-row class="pa-0 ma-0 pa-0 task_fb" justify="space-between" align="start">
          <v-col cols="2" class="cont_tfb ma-2">
            <v-row class="pa-1">
              <span class="text-subtitle-1 font-weight-bold" style="width:80px; color: #2d83cf;">
                任务
              </span>
              <span>
                {{this.dObject.obj?.task_name}}
              </span>
            </v-row>
            <v-row class="pa-1">
              <span class="text-subtitle-1 font-weight-bold" style="width:80px;">
                <v-icon small style="color: #2d83cf;">mdi-menu</v-icon>
              </span>
              <span v-html="this.dObject.obj.task_description?this.dObject.obj.task_description:'暂无描述'">
              </span>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row class="pa-1 pr-4" justify="end">
              <span class="pr-1">
                负责人：{{getOwnerName()}}
              </span>
              <UserAvatar :fullName="getOwnerName()" :name="getOwnerName().slice(0,1)" width='30px' height='30px' :userId="getOwnerId()" />
            </v-row>
            <v-row class="pa-1">
              <span>
              </span>
            </v-row>
          </v-col>
        </v-row>

        <v-divider light></v-divider>

        <v-row class="pa-0 ma-0 pa-0 task_fb" justify="space-between" align="start">
          <Operating :op_obj="{op_parent:this.dObject.obj.task_id, op_parent_type:2}" />
        </v-row>
        <v-row class="pa-0 ma-0 pa-0 " justify="space-between" align="start">
          <span style=" color: #ccc;">
            {{ this.dObject.obj.created_at | chinaFormatDate }}
          </span>
        </v-row>
      </v-container>

      <v-container v-if="this.dObject.mode=='item'">
        <v-row class="pa-0 ma-0 pa-0 task_fb" justify="space-between" align="start">
          <v-col cols="3" class="cont_tfb  ma-2">
            <v-row class="pa-1">
              <span class="text-subtitle-1 font-weight-bold" style="width:80px; color: #2d83cf;">
                项目
              </span>
              <span>
                {{this.dObject.obj.item_name}}
              </span>
            </v-row>
            <v-row class="pa-1">
              <span class="grey--text text--darken-2" v-html="this.dObject.obj.item_description?this.dObject.obj.item_description:'暂无描述'">
              </span>
            </v-row>
						<v-row class="pa-1">
              <span class="grey--text text--darken-2">
									起止时间：{{ this.dObject.obj.item_start_date | formatDate}} - {{ this.dObject.obj.item_end_date | formatDate}}
              </span>
            </v-row>
						<v-row class="pa-1 align-center grey--text text--darken-2" >
								<span>
									完成度：
								</span>
								<v-col cols="4">
									<v-progress-linear height="8" rounded :value="this.dObject.obj.item_progress"></v-progress-linear>
								</v-col>
								<span>
									{{ this.dObject.obj.item_progress}}%
								</span>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row class="pa-1 pr-4" justify="end">
              <span class="pr-1">
                负责人：{{getOwnerName()}}
              </span>
              <UserAvatar :fullName="getOwnerName()" :name="getOwnerName().slice(0,1)" width='30px' height='30px' :userId="getOwnerId()" />
            </v-row>
            <v-row class="pa-1">
              <span>
              </span>
            </v-row>
          </v-col>
        </v-row>

        <v-divider light></v-divider>

        <v-row class="pa-0 ma-0 pa-0 task_fb" justify="space-between" align="start">
          <Operating :op_obj="{op_parent:this.dObject.obj.item_id, op_parent_type:3}" />
        </v-row>
        <v-row class="pa-0 ma-0 pa-0 " justify="space-between" align="start">
          <span style=" color: #ccc;">
            {{ this.dObject.obj.created_at | chinaFormatDate }}
          </span>
        </v-row>
      </v-container>

			<v-container v-if="this.dObject.mode=='milestone'">
        <v-row class="pa-0 ma-0 pa-0 task_fb" justify="space-between" align="start">
          <v-col cols="3" class="cont_tfb  ma-2">
            <v-row class="pa-1">
              <span class="text-subtitle-1 font-weight-bold" style="width:80px; color: #2d83cf;">
                里程碑
              </span>
              <span>
                {{this.dObject.obj.ms_name}}
              </span>
            </v-row>
            <v-row class="pa-1">
              <span class="grey--text text--darken-2" v-html="this.dObject.obj.ms_description?this.dObject.obj.ms_description:'暂无描述'">
              </span>
            </v-row>
						<v-row class="pa-1">
              <span class="grey--text text--darken-2">
									起止时间：{{ this.dObject.obj.ms_start_date | formatDate}} - {{ this.dObject.obj.ms_end_date | formatDate}}
              </span>
            </v-row>
						<v-row class="pa-1 align-center grey--text text--darken-2" >
								<span>
									完成度：
								</span>
								<v-col cols="4">
									<v-progress-linear height="8" rounded :value="this.dObject.obj.ms_progress"></v-progress-linear>
								</v-col>
								<span>
									{{ this.dObject.obj.ms_progress}}%
								</span>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row class="pa-1 pr-4" justify="end">
              <span class="pr-1">
                负责人：{{getOwnerName()}}
              </span>
              <UserAvatar :fullName="getOwnerName()" :name="getOwnerName().slice(0,1)" width='30px' height='30px' :userId="getOwnerId()" />
            </v-row>
            <v-row class="pa-1">
              <span>
              </span>
            </v-row>
          </v-col>
        </v-row>

        <v-divider light></v-divider>

        <v-row class="pa-0 ma-0 pa-0 task_fb" justify="space-between" align="start">
          <Operating :op_obj="{op_parent:this.dObject.obj.ms_id, op_parent_type:13}" />
        </v-row>
        <v-row class="pa-0 ma-0 pa-0 " justify="space-between" align="start">
          <span style=" color: #ccc;">
            {{ this.dObject.obj.created_at | chinaFormatDate }}
          </span>
        </v-row>
      </v-container>

    </v-container>

  </div>
</div>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';

export default {
  name: 'DongTaiItem',
  props: ['dObject', ],
  components: {
    UserPicker: () => import('@/components/common/UserPicker.vue'),
    Operating: () => import('@/components/common/operating/Operating.vue'),
    Intercom: () => import("@/components/common/Intercom/Intercom.vue"),
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
  },
  data() {
    return {
      enableIcSubmitBtn: false,
      objectLabel: {
        'task': '发起的任务',
        'item': '',
        'intercom': '说：',
        'milestone': '发起的里程碑',
        'share':"分享"
      },
    }
  },
  mounted() {
    
    
  },
  computed: {
    ...mapGetters('user', ['users']),
    ...mapGetters("intercom", ["intercom"]),
  },
  methods: {
    getOwnerName() {
      switch (this.dObject.mode) {
        case 'task':
          return this.dObject.obj.task_owner_name;
        case 'item':
          return this.getUserName(this.dObject.obj.item_owner);
        case 'intercom':
          return this.dObject.obj.ic_sender_name;
        case 'milestone':
          return this.getUserName(this.dObject.obj.ms_owner);
        case 'share':
          return this.dObject.obj.ic_sender_name;
        default:
          return 'default';
          break;
      }
    },
    getOwnerId() {
      switch (this.dObject.mode) {
        case 'task':
          return this.dObject.obj.task_owner;
        case 'item':
          return this.dObject.obj.item_owner;
        case 'intercom':
          return this.dObject.obj.ic_sender;
        case 'milestone':
          return this.dObject.obj.ms_owner;
        case 'share':
          return this.dObject.obj.ic_sender;
        default:
          return '1';
          break;
      }
    },
    getUserName(userId) {
      return this.users.filter(e => {
        return e.id == userId;
      })[0]?.employeeName;
    }
  },
}
</script>

<style>
.dongtai-item {
  padding: 10px 5px 10px 70px;
}

.dt-avatar {
  float: left;
  position: relative;
  margin-right: -60px;
  right: 60px;
}

.dt-content {
  position: relative;
}

.cont_tfb {
  /* padding-right: 258px; */
  border-left: 3px solid #509fe5;
}

.task_fb {
  position: relative;
  min-height: 50px;
  background-color: #f4faff;
  padding: 8px 0 8px 8px;
}
</style>
